import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/site/src/components/episodeLayout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Sammanfattning`}</h2>
    <p>{`Vi diskuterar framtiden för Firefox Send, Rust och MDN efter Mozillas stora omstrukturering. Vi tipsar om backuper med Duplicati och Alex kanske blir bannad från Mastodon.`}</p>
    <h2>{`Nyheter`}</h2>
    <ul>
      <li parentName="ul">{`Rust blickar framåt: `}<a parentName="li" {...{
          "href": "https://blog.rust-lang.org/2020/08/18/laying-the-foundation-for-rusts-future.html"
        }}>{`https://blog.rust-lang.org/2020/08/18/laying-the-foundation-for-rusts-future.html`}</a></li>
      <li parentName="ul">{`Nextcloud-klienter uppdateras: `}<a parentName="li" {...{
          "href": "https://nextcloud.com/blog/production-ready-end-to-end-encryption-and-new-user-interface-arrive-with-nextcloud-desktop-client-3-0/"
        }}>{`https://nextcloud.com/blog/production-ready-end-to-end-encryption-and-new-user-interface-arrive-with-nextcloud-desktop-client-3-0/`}</a></li>
      <li parentName="ul">{`Mozilla uppdaterar oss om MDN: `}<a parentName="li" {...{
          "href": "https://hacks.mozilla.org/2020/08/an-update-on-mdn-web-docs/"
        }}>{`https://hacks.mozilla.org/2020/08/an-update-on-mdn-web-docs/`}</a></li>
      <li parentName="ul">{`Firefox Send: `}<a parentName="li" {...{
          "href": "https://support.mozilla.org/sv/kb/what-happened-firefox-send"
        }}>{`https://support.mozilla.org/sv/kb/what-happened-firefox-send`}</a></li>
    </ul>
    <h2>{`Trevligheter`}</h2>
    <ul>
      <li parentName="ul">{`Duplicati: `}<a parentName="li" {...{
          "href": "https://www.duplicati.com/"
        }}>{`https://www.duplicati.com/`}</a>{` `}</li>
      <li parentName="ul">{`Flatseal: `}<a parentName="li" {...{
          "href": "https://github.com/tchx84/Flatseal"
        }}>{`https://github.com/tchx84/Flatseal`}</a></li>
      <li parentName="ul">{`Gammalt grafikkort? Linux till räddning: `}<a parentName="li" {...{
          "href": "https://www.reddit.com/r/linux_gaming/comments/ic6c8h/asketi4_github_is_playing_death_stranding_on_mint/"
        }}>{`https://www.reddit.com/r/linux_gaming/comments/ic6c8h/asketi4_github_is_playing_death_stranding_on_mint/`}</a></li>
    </ul>
    <h2>{`Utmaningar`}</h2>
    <h3>{`Alex Rust-äventyr`}</h3>
    <ul>
      <li parentName="ul">{`GraphQL `}</li>
    </ul>
    <h3>{`Seb`}</h3>
    <ul>
      <li parentName="ul">{`Refaktorierar och Optimerar `}</li>
    </ul>
    <h2>{`Meta`}</h2>
    <ul>
      <li parentName="ul">{`Alex famlar runt på mastodon`}</li>
      <li parentName="ul">{`dsvdv tipsar om Wallabag`}</li>
    </ul>
    <h2>{`Kontakta oss`}</h2>
    <ul>
      <li parentName="ul">{`Hemsida: `}<a parentName="li" {...{
          "href": "https://trevligmjukvara.se"
        }}>{`https://trevligmjukvara.se`}</a></li>
      <li parentName="ul">{`Mail: `}<a parentName="li" {...{
          "href": "mailto:kontakt@trevligmjukvara.se"
        }}>{`kontakt@trevligmjukvara.se`}</a></li>
      <li parentName="ul">{`Twitter: `}<a parentName="li" {...{
          "href": "https://twitter.com/trevligmjukvara"
        }}>{`@trevligmjukvara`}</a></li>
      <li parentName="ul">{`Telegram: `}<a parentName="li" {...{
          "href": "https://t.me/trevligmjukvara"
        }}>{`Trevlig Mjukvara`}</a></li>
      <li parentName="ul">{`Mastodon: `}<a parentName="li" {...{
          "href": "https://fikaverse.club/@trevligmjukvara"
        }}>{`@trevligmjukvara`}</a></li>
      <li parentName="ul">{`GitHub: `}<a parentName="li" {...{
          "href": "https://github.com/trevligmjukvara"
        }}>{`Trevlig Mjukvara`}</a></li>
      <li parentName="ul">{`Alex: `}<a parentName="li" {...{
          "href": "https://mastodon.online/@_alexander_"
        }}>{`@`}<em parentName="a">{`alexander`}</em></a></li>
      <li parentName="ul">{`Seb: `}<a parentName="li" {...{
          "href": "https://twitter.com/Kikkusrikkus"
        }}>{`@Kikkusrikkus`}</a></li>
    </ul>
    <h2>{`Tack till`}</h2>
    <ul>
      <li parentName="ul">{`Ljudeffekter från `}<a parentName="li" {...{
          "href": "https://www.zapsplat.com/"
        }}>{`https://www.zapsplat.com/`}</a></li>
      <li parentName="ul">{`Musik från `}<a parentName="li" {...{
          "href": "https://filmmusic.io"
        }}>{`https://filmmusic.io`}</a><ul parentName="li">
          <li parentName="ul">{`"Pixelland" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"NewsSting" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"Toccata and Fugue in D Minor" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"Beautiful World - Epic and Uplifting Motivational Trailer" av Rafael Krux`}</li>
          <li parentName="ul">{`"Dreamy Flashback" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"Black Knight" av Rafael Krux`}</li>
          <li parentName="ul">{`"Pixel Peeker Polka - Faster" av Kevin MacLeod`}</li>
          <li parentName="ul">{`Licens för alla: CC BY 4.0: `}<a parentName="li" {...{
              "href": "http://creativecommons.org/licenses/by/4.0/"
            }}>{`http://creativecommons.org/licenses/by/4.0/`}</a></li>
        </ul></li>
      <li parentName="ul">{`Grafik och font i loggan:`}<ul parentName="li">
          <li parentName="ul">{`Ok-emoji: emojione version 2.2.7, `}<a parentName="li" {...{
              "href": "https://commons.wikimedia.org/wiki/File:Emojione_1F44C.svg"
            }}>{`https://commons.wikimedia.org/wiki/File:Emojione_1F44C.svg`}</a>{` (CC BY 4.0)`}</li>
          <li parentName="ul">{`Font: Sulphur Point av Dale Sattler, `}<a parentName="li" {...{
              "href": "https://fonts.google.com/specimen/Sulphur+Point"
            }}>{`https://fonts.google.com/specimen/Sulphur+Point`}</a>{` (OFL)`}</li>
        </ul></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      